// Template.
<template>
  <video-player :options="videoOptions" />
</template>


// Script.
<script>
import VideoPlayer from './VideoPlayer.vue';

export default {
  name: 'VideoPlayerContainer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {
    VideoPlayer
  },
  data() {
    return {
      videoOptions: {
        autoplay: false,
        controls: true,
        width: this.options.width,
        sources: [
          {
            src: this.options.src,
            type: this.options.type
          },
        ]
      }
    };
  }
};
</script>
// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #ffbec2 !important;
}
</style>