// Template.
<template>
  <v-card class="mx-auto pa-4" max-width="800" elevation="0">
    <template>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="larsseit-bold">
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text class="larsseit">{{ dialogMessage }}</v-card-text>
          <v-card-text class="larsseit">{{ dialogSubMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="cancelShow"
              class="simplon-norm"
              color="#1A4851"
              text
              @click="dialogCancel"
            >
              {{ dialogDisagree }}
            </v-btn>
            <v-btn
              v-if="confirmShow"
              class="simplon-norm"
              color="#1A4851"
              text
              @click="dialogConfirm"
            >
              {{ dialogAgree }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ProgressDialogue
        v-bind:dialog="showSpinner"
        v-bind:progressValue="progressValue"
        v-bind:progressText="progressText"
        v-bind:textLabel="'Fetching Zip Status...'"
        v-bind:title="'Preparing download'"
      />

      <div class="empty--state" align="center" v-if="noItems">
        Your jobs will go here.
      </div>

      <div>
        <v-card elevation="0" v-for="(item, index) in items" :key="index">
          <v-card-text>
            <v-container pa-0 elevation="0">
              <v-row align="center" no-gutters>
                <v-col
                  cols="6"
                  class="reely--project larsseit-bold dark--blue"
                  >{{ item.project }}</v-col
                >
                <v-col
                  v-if="item.firstProject"
                  cols="6"
                  class="text-right reely--textsize simplon-norm"
                  >{{ item.pay }}</v-col
                >
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="reely--name larsseit-bold">{{
                  item.name
                }}</v-col>
              </v-row>
              <v-col col="12"> </v-col>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.duration
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">
                  Total Clips: {{ item.storageURLs.length }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.orientation
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.upgrade
                }}: {{ item.upgradeExist ? "Yes" : "No" }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.mood
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.notes
                }}</v-col>
              </v-row>
              <v-col col="12"> </v-col>

              <v-file-input
                v-if="!item.edited"
                accept=".prproj"
                v-model="item.adobeProjectFile"
                placeholder="Upload adobe project file here."
                prepend-icon="mdi-paperclip"
                color="#F8B2B6"
                class="larsseit"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="#F8B2B6">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <v-file-input
                v-if="!item.edited"
                accept="video/*"
                v-model="item.editedProjectFile"
                placeholder="Upload video file here."
                prepend-icon="mdi-paperclip"
                color="#F8B2B6"
                class="larsseit"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="#F8B2B6">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <v-alert
                v-if="item.submitError"
                class="mt-4 error2"
                color="#D9B029"
                max-width="750"
                dense
                outlined
                type="error"
              >
                {{
                  "Wrong files selected for submission. Please select one video file for the final film, the Adobe Premiere Pro file associated with the project, and click Submit again."
                }}
              </v-alert>

              <v-col col="12"> </v-col>

              <v-row
                v-if="item.lastProject && !item.edited"
                justify="space-around"
              >
                <v-btn
                  :loading="cancelLoading"
                  @click="cancelJob(item.docId)"
                  text
                  class="reely--text"
                  >Cancel Job</v-btn
                >

                <v-spacer></v-spacer>

                <v-btn
                  @click="downloadFiles(item.docId)"
                  text
                  class="reely--text"
                  >Download Files</v-btn
                >

                <v-spacer></v-spacer>

                <v-btn
                  @click="submit(item.docId, index)"
                  text
                  class="reely--text"
                  >Submit</v-btn
                >
              </v-row>

            
              <!-- <div class="larsseit" v-if="item.lastProject && item.edited">
                {{ item.orderSubmitted }}
              </div> -->

              <v-row justify="space-around" v-if="item.lastProject && item.edited">
                <!-- <v-col
                  cols="6"
                  class="larsseit"
                  > -->
                  <div class="larsseit">
                    {{ item.orderSubmitted }}
                  </div>
                <!-- </v-col> -->

                <v-spacer></v-spacer>

                <!-- <v-col
                  cols="6"> -->

                    <v-btn
                      :loading="cancelSubmissionLoading"
                      @click="cancelSubmission(item.docId)"
                      text
                      class="reely--text"
                      >Cancel Submission</v-btn
                    >

                  <!-- </v-col> -->
              </v-row>

              <v-col v-if="item.lastProject" col="12"> </v-col>

              <v-row v-if="item.lastProject">
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </v-card>
</template>

// Script.
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import "firebase/auth";
import util, { uploadToS3 } from "@/util";
import multiDownload from "multi-download";
import "firebase/storage";
import "firebase/functions";
import { getUrl, downloadFromS3 } from '../util';
import ProgressDialogue from '../components/ProgressDialogue.vue';

const downloadState = "download";
const cancelState = "cancel";
const cancelSubmissionState = "cancelSubmission";
const submitState = "submit";
const uploadState = "upload";

export default {
  components: {
    ProgressDialogue
  },
  data: () => ({
    selected: null,
    items: [],
    noItems: false,
    dialog: false,
    dialogTitle: "Example Title",
    dialogMessage: "Example Message",
    dialogSubMessage: "",
    dialogDisagree: "No",
    dialogAgree: "Yes",
    dialogState: "",
    downloadUrls: null,
    cancelJobDocId: null,
    cancelSubmissionDocId: null,
    cancelLoading: false,
    cancelSubmissionLoading: false,
    submitDocId: null,
    submitIndex: null,
    cancelShow: true,
    confirmShow: true,
    showSpinner: false,
    progressText: null,
    progressValue: 0
  }),
  methods: {

    cancelSubmission(docId) {
      this.cancelSubmissionDocId = docId;
      this.showDialog(
        "Cancel Submission?",
        "Are you sure you want to cancel this submission?",
        "Cancel Submission",
        "Exit",
        cancelSubmissionState
      );
    },
    // Cancel job with document id.
    cancelJob(docId) {
      this.cancelJobDocId = docId;
      this.showDialog(
        "Cancel Job?",
        "Canceling a job will make it available to other editors. Are you sure you want to cancel?",
        "Cancel Job",
        "Exit",
        cancelState
      );
    },

    async cancelSubmissionAction() {
      console.log("cancelSubmissionAction: " + this.cancelSubmissionDocId);
      
      const updateData = {
        editedVideos: firebase.firestore.FieldValue.delete(),
        edited: firebase.firestore.FieldValue.delete(),
        dateSubmitted: firebase.firestore.FieldValue.delete(),
        editorId: ""
      };
      await firebase
        .firestore()
        .collection("orders")
        .doc(this.cancelSubmissionDocId)
        .update(updateData);

      this.$router.push("/");
    },

    // Cacnel job action.
    cancelJobAction() {
      var cancelJobThis = this;

      cancelJobThis.cancelLoading = true;

      const docId = cancelJobThis.cancelJobDocId;

      var clearEditorToken = firebase
        .functions()
        .httpsCallable("clearEditorToken");
      clearEditorToken()
        .then((result) => {
          if (result.data.error == null) {
            firebase
              .auth()
              .currentUser.getIdToken()
              .then(function() {
                firebase
                  .firestore()
                  .collection("orders")
                  .doc(docId)
                  .update({
                    editorId: "",
                  })
                  .then(() => {
                    firebase
                      .firestore()
                      .collection("editors")
                      .doc(firebase.auth().currentUser.email)
                      .update({
                        orderId: firebase.firestore.FieldValue.delete(),
                      })
                      .then(() => {
                        cancelJobThis.$router.push("/");
                      })
                      .catch(function(error) {});
                  })
                  .catch(function(error) {});
              })
              .catch(function(error) {
                console.log(error);
              });
          } else {
            console.log("no token in result data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Download files with urls.
    async downloadFiles(orderId) {
      let downloadFilesThis = this;

      // downloadFilesThis.downloadUrls = [res.data];
      downloadFilesThis.orderId = orderId;
      downloadFilesThis.showDialog(
        "Download Project Files?",
        "You are about to download all project files as Zip. If this is your first request it can take sometime before starting to download.",
        "Download",
        "Cancel",
        downloadState
      );
    },

    // Show dialog with title, message, confirm, cancel and state.
    showDialog(title, message, confirm, cancel, state) {
      this.dialogState = state;
      this.dialog = true;
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogAgree = confirm;
      this.dialogDisagree = cancel;
      if (state == uploadState) {
        this.cancelShow = false;
        this.confirmShow = false;
      } else {
        this.cancelShow = true;
        this.confirmShow = true;
      }
    },

    // Cancel dialog.
    dialogCancel() {
      this.dialog = false;
    },

    // Confirm dialog.
    async dialogConfirm() {
      this.dialog = false;
      let zipRequested = false;
      
      if (this.dialogState == downloadState) {
        this.showSpinner = true;
        const unsubscribe = firebase
          .firestore()
          .collection("orders")
          .doc(this.orderId)
          .onSnapshot(async obs => {
            const data = obs.data();
            console.log(data)
            if (!data.zipping && !data.zipped ) {
              if (zipRequested) {
                if (data.zipFailCount) {
                  this.progressText = `Zip request failed ${data.zipFailCount} times.`;
                }
                return;
              }
              zipRequested = true;
              this.progressText = 'Videos are not zipped yet. Requesting...';
              const queueZipRequest = firebase.functions().httpsCallable("queueZip");
              await queueZipRequest({ orderId: this.orderId });
              return;
            }
            if (!data.zipping && data.zipFailCount >= 3) {
              this.progressText = 'Could not zip video files after multiple attempts. Contact admin.';
              return;
            }
            if (!data.zippedProcessed) {
              if (data.zipFilesCount) {
                this.progressText = `0 / ${data.zipFilesCount} videos zipped.`;
                this.progressValue = 0;
              }
              return;
            }
            this.progressText = `${data.zippedProcessed} / ${data.zipFilesCount} videos zipped.`;
            this.progressValue = (data.zippedProcessed / data.zipFilesCount) * 100;
            console.log(this.progressText);
            console.log(this.progressValue)

            if (!data.zipFilePath) {
              return;
            }

            const zipFilePath = data.zipFilePath;

            unsubscribe();
            this.showSpinner = false;

            const url = await downloadFromS3(firebase, zipFilePath);
            console.log(`url: ${url}`);
            multiDownload([url]);
            this.progressText = null;
        });
        // try {
        //   const downloadZip = firebase.functions().httpsCallable("downloadZip");
        //   const res = await downloadZip({ orderId: this.orderId });
        //   console.log(res);
        //   // if (!res) {
        //   //   throw new Error('zip request failed.');
        //   // }
        // } catch(e) {
        //   console.log(e);
        // }
      } else if (this.dialogState == cancelState) {
        this.cancelJobAction();
      } else if (this.dialogState == submitState) {
        this.submitAction();
      } else if (this.dialogState == cancelSubmissionState) {
        this.cancelSubmissionAction();
      }
    },

    // Submit action.
    submitAction() {
      let submitActionThis = this;

      submitActionThis.showDialog(
        "Uploading Order...",
        "Do not close out of this screen until pop-up is dismissed.",
        "",
        "",
        uploadState
      );

      const docId = submitActionThis.submitDocId;
      const adobeFiles = submitActionThis.submitAdobeFiles;
      const editedUrls = submitActionThis.submitEditedUrls;
      const finalProjectIds = submitActionThis.submitFinalProjectIds;
      const videoIndex = submitActionThis.submitVideoIndex;
      const submitThis = submitActionThis.submitSubmitThis;
      const projectNames = submitActionThis.submitProjectNames;
      const finalEditedVideos = submitActionThis.submitFinalEditedVideos;

      console.log(submitActionThis);

      var setEditorToken = firebase.functions().httpsCallable("setEditorToken");
      setEditorToken()
        .then((result) => {
          if ("token" in result.data) {
            firebase
              .auth()
              .signInWithCustomToken(result.data.token)
              .then(function() {
                submitActionThis.uploadFiles(
                  docId,
                  adobeFiles,
                  editedUrls,
                  finalProjectIds,
                  videoIndex,
                  submitThis,
                  projectNames,
                  finalEditedVideos
                );
              })
              .catch(function(error) {
                console.log(error);
              });
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Submit with document id and index.
    submit(docId, index) {
      var submitThis = this;

      let editedUrls = [];
      let adobeFiles = [];
      let readyToSubmit = true;
      const finalProjectIds = [];
      const projectNames = [];
      const finalEditedVideos = [];

      const errorIndexes = [];

      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].docId == docId) {
          // finalProjectIds[i] = this.items[i].projectId;
          // projectNames[i] = this.items[i].project;
          finalProjectIds.push(this.items[i].projectId);
          projectNames.push(this.items[i].project);
          try {
            if (this.items[i].adobeProjectFile) {
              adobeFiles.push(this.items[i].adobeProjectFile);
            }
            if (this.items[i].editedProjectFile) {
              editedUrls.push(this.items[i].editedProjectFile);
            }
            if (!adobeFiles.length || !editedUrls.length) {
              readyToSubmit = false;
              errorIndexes.push(i);
            }
          } catch(e) {
            readyToSubmit = false;
            errorIndexes.push(i);
          }
        }
      }

      if (readyToSubmit) {
        submitThis.items[index].submitError = false;

        submitThis.submitDocId = docId;
        submitThis.submitAdobeFiles = adobeFiles;
        submitThis.submitEditedUrls = editedUrls;
        submitThis.submitFinalProjectIds = finalProjectIds;
        submitThis.submitVideoIndex = 0;
        submitThis.submitSubmitThis = submitThis;
        submitThis.submitProjectNames = projectNames;
        submitThis.submitFinalEditedVideos = finalEditedVideos;

        for (var i = 0; i < submitThis.items.length; i++) {
          submitThis.items[i].submitError = false;
        }

        submitThis.showDialog(
          "Submit Mini-Film?",
          "The customer will be able to download the film after submission. Please make sure all files are correct.",
          "Submit",
          "Cancel",
          submitState
        );
      } else {
        for (var i = 0; i < submitThis.items.length; i++) {
          submitThis.items[i].submitError = false;
        }

        for (var i = 0; i < errorIndexes.length; i++) {
          let errorIndex = errorIndexes[i];
          submitThis.items[errorIndex].submitError = true;
        }
      }
    },

    uploadProgressCallback(progressEvent) {
      const totalUploadedMb = progressEvent.loaded / 1000000;
      const totalProgress = (progressEvent.loaded / progressEvent.total) * 100;
      this.dialogSubMessage = `Upload Progress: ${totalUploadedMb.toFixed(2)}Mb (${totalProgress.toFixed(2)}%)`;
      console.log(totalUploadedMb, totalProgress);
    },

    // Upload files with document id, adobe files,
    // video files, final project ids, index, submitThis,
    // project names and final edited videos.
    uploadFiles(
      docId,
      adobeFiles,
      videoFiles,
      finalProjectIds,
      index,
      submitThis,
      projectNames,
      finalEditedVideos
    ) {
      console.log("Got here 1");

      let adobeFile = adobeFiles[index];
      const p = projectNames[index] + "." + adobeFile.name.split(".").pop();
      console.log(p);

      firebase
        .storage()
        .ref()
        .child("orders")
        .child(docId)
        .child(finalProjectIds[index])
        .child(p)
        .put(adobeFile)
        .then((snapshot) => {
          console.log("Got here 1-2");

          let videoFile = videoFiles[index];
          const key = `edited/${docId}/${finalProjectIds[index]}/${projectNames[index] + "." + videoFile.name.split(".").pop()}`;
          // firebase
          //   .storage()
          //   .ref()
          //   .child("orders")
          //   .child(docId)
          //   .child(finalProjectIds[index])
          //   .child(projectNames[index] + "." + videoFile.name.split(".").pop())
          //   .put(videoFile)
          uploadToS3(firebase, key, videoFile, this.uploadProgressCallback)
            .then((downloadURL) => {
              // snapshot.ref
                // .getDownloadURL()
                // .then(function(downloadURL) {
                  finalEditedVideos.push(downloadURL);

                  var updateData = {
                    editedVideos: finalEditedVideos,
                  };
                  if (finalEditedVideos.length == videoFiles.length) {
                    updateData = {
                      editedVideos: finalEditedVideos,
                      edited: true,
                      dateSubmitted: firebase.firestore.FieldValue.serverTimestamp(),
                    };
                  }

                  console.log("Got here 2");

                  firebase
                    .firestore()
                    .collection("orders")
                    .doc(docId)
                    .update(updateData)
                    .then(() => {
                      console.log("Got here 3");

                      if (index != videoFiles.length - 1) {
                        index = index + 1;
                        submitThis.uploadFiles(
                          docId,
                          adobeFiles,
                          videoFiles,
                          finalProjectIds,
                          index,
                          submitThis,
                          projectNames,
                          finalEditedVideos
                        );
                      } else {
                        firebase
                          .firestore()
                          .collection("editors")
                          .doc(firebase.auth().currentUser.email)
                          .update({
                            orderId: firebase.firestore.FieldValue.delete(),
                          })
                          .then(() => {
                            for (var i = 0; i < submitThis.items.length; i++) {
                              for (var j = 0; j < finalProjectIds.length; j++) {
                                if (
                                  submitThis.items[i].projectId ==
                                  finalProjectIds[j]
                                ) {
                                  submitThis.items[i].edited = true;
                                  submitThis.items[i].orderSubmitted =
                                    "Order Submitted Successfully";
                                  break;
                                }
                              }
                            }

                            submitThis.dialog = false;
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                // })
                // .catch((error) => {
                //   console.log(error);
                // });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    let that = this;

    firebase
      .firestore()
      .collection("orders")
      .where("editorId", "==", firebase.auth().currentUser.uid)
      .orderBy("dateAccepted", "desc")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let data = doc.data();
          let projectCount = data.projectIDs.length;
          for (var i = 0; i < projectCount; i++) {
            that.items.push({
              firstProject: i == 0,
              lastProject: i == projectCount - 1,
              project: data.titles[i],
              name: data.name,
              pay: util.payString(data),
              duration: util.durationString(data.durations[i]),
              upgrade: "Upgrades: Color Correction",
              upgradeExist: data.colorCorrections[i],
              mood: util.moodString(data.moods[data.projectIDs[i]]),
              notesExist: data.notes[i] != "",
              notes: util.notesString(data.notes[i]),
              date: moment(data.dateOrdered.toDate()).fromNow(),
              docId: doc.id,
              storageURLs: getStorageURLs(data),
              projectId: data.projectIDs[i],
              edited: data.edited,
              orientation: util.orientationString(data.orientations[i]),
              orderSubmitted: getOrderSubmittedString(data.dateSubmitted),
              submitError: false,
              adobeProjectFile: null,
              editedProjectFile: null,
            });
          }
        });
        that.noItems = querySnapshot.docs.length == 0;
      })
      .catch(function(error) {
        console.log(error);
      });
  },
};

// Get order submitted string with timestamp.
function getOrderSubmittedString(timestamp) {
  if (timestamp == null) {
    return "";
  }
  return "Order Submitted " + moment(timestamp.toDate()).fromNow();
}

// Get storage urls with data.
function getStorageURLs(data) {
  var finalStoarageURLs = [];
  for (var storageId in data.storageURLs) {
    for (var j = 0; j < data.projectIDs.length; j++) {
      if (storageId == data.projectIDs[j]) {
        for (var i = 0; i < data.storageURLs[storageId].length; i++) {
          finalStoarageURLs.push(data.storageURLs[storageId][i]);
        }
        break;
      }
    }
  }
  return finalStoarageURLs;
}
</script>

// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #f8b2b6 !important;
}

.reely--project {
  font-size: 18px;
}

.reely--name {
  font-size: 14px;
  color: #f8b2b6;
}

.reely--details {
  font-size: 16px;
}

.loader-container {
  align-self: center;
}

.spinner-container {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.spinner-container p {
  text-align: left;
  padding: 10px;
}

</style>
