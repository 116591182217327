// Template.
<template  >
  <v-card class="mx-auto pa-4" max-width="800" elevation="0">
    <template>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="larsseit-bold">
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text class="larsseit">{{ dialogMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="true"
              class="simplon-norm"
              color="#1A4851"
              text
              @click="dialogCancel"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <div align="center" class="empty--state" v-if="noItems">
        There are currently no jobs available.
      </div>

      <div>
        <v-card elevation="0" v-for="(item, index) in items" :key="index">
          <v-card-text>
            <v-container pa-0 elevation="0">
              <v-row align="center" no-gutters>
                <v-col
                  cols="6"
                  class="reely--project larsseit-bold dark--blue"
                  >{{ item.project }}</v-col
                >
                <v-col
                  v-if="item.firstProject"
                  cols="6"
                  class="text-right reely--textsize simplon-norm"
                  >{{ item.pay }}</v-col
                >
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="reely--name larsseit-bold">{{
                  item.name
                }}</v-col>
              </v-row>
              <v-col col="12"> </v-col>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.duration
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">
                  Total Clips: {{ item.storageURLs.length }}
                </v-col>
              </v-row>


              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.orientation
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.upgrade
                }}: {{ item.upgradeExist ? "Yes" : "No" }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.mood
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col
                  v-if="item.notesExist"
                  cols="12"
                  class="text-secondary larsseit"
                  >{{ item.notes }}</v-col
                >
              </v-row>

              <v-col v-if="item.lastProject" col="12"> </v-col>

              <v-row no-gutters align="center">
                <v-col v-if="item.lastProject" cols="6" class="simplon-norm">{{
                  item.date
                }}</v-col>
                <v-col cols="6" class="text-right">
                  <v-btn
                    :loading="item.acceptLoading"
                    @click="acceptJob(item.docId, index)"
                    v-if="item.lastProject"
                    outlined
                    class="reely--text simplon-norm"
                  >
                    Accept Job
                  </v-btn>
                </v-col>
              </v-row>

              <v-col col="12"> </v-col>

              <v-row>
                <v-col v-if="item.lastProject" cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </v-card>
</template>
 
// Script.
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import "firebase/auth";
import util from "@/util";
import Swal from "sweetalert2";

export default {
  data: () => ({
    user: {
      loggedIn: false,
      data: {},
    },
    selected: null,
    items: [],
    noItems: false,
    dialog: false,
    dialogTitle: "Example Title",
    dialogMessage: "Example Message",
    isMounted: false,
  }),
  methods: {

    // Show dialog.
    showDialog(title, message) {
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialog = true;
    },

    // Cancel dialog.
    dialogCancel() {
      this.dialog = false;
    },

    // Accept job with document id and index.
    acceptJob(docId, index) {
      let accceptJobThis = this;

      accceptJobThis.items[index].acceptLoading = true;

      firebase
        .firestore()
        .collection("editors")
        .doc(firebase.auth().currentUser.email)
        .get()
        .then((snapshot) => {
          if (!("orderId" in snapshot.data())) {
            firebase
              .firestore()
              .collection("orders")
              .doc(docId)
              .update({
                editorId: firebase.auth().currentUser.uid,
                dateAccepted: firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then(() => {
                firebase
                  .firestore()
                  .collection("editors")
                  .doc(firebase.auth().currentUser.email)
                  .update({
                    orderId: docId,
                  })
                  .then(() => {
                    accceptJobThis.$router.push("/myjobs");
                  });
              })
              .catch(function (error) {
                if (error.code == "permission-denied") {
                  accceptJobThis.showDialog(
                    "Job Not Available",
                    "This job is no longer available."
                  );
                }

                accceptJobThis.items[index].acceptLoading = false;
              });
          } else {
            accceptJobThis.showDialog(
              "Job Not Accepted",
              "You can only accept one job at a time."
            );

            accceptJobThis.items[index].acceptLoading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    
    let that = this;

    // Fetch all jobs.
    firebase
      .firestore()
      .collection("orders")
      .where("uploaded", "==", true)
      .where("editorId", "==", "")
      .orderBy("dateOrdered", "desc")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let data = doc.data();
          let projectCount = data.projectIDs.length;
          for (var i = 0; i < projectCount; i++) {
            if (data.isArchived) {
              continue;
            }
            that.items.push({
              firstProject: i == 0,
              lastProject: i == projectCount - 1,
              project: data.titles[i],
              name: data.name,
              pay: util.payString(data),
              duration: util.durationString(data.durations[i]),
              upgrade: "Upgrades: Color Correction",
              upgradeExist: data.addons && data.addons.includes("digital-color"),
              mood: util.moodString(data.moods[data.projectIDs[i]]),
              notes: "Notes to Editor: " + data.notes[i],
              notesExist: data.notes[i] != "",
              storageURLs: getStorageURLs(data),
              date: moment(data.dateOrdered.toDate()).fromNow(),
              orientation: util.orientationString(data.orientations[i]),
              docId: doc.id,
              acceptLoading: false,
            });
          }
          console.log(that.items)
        });
        that.noItems = that.items.length == 0;
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  },
  
};
function getStorageURLs(data) {
  var finalStoarageURLs = [];
  for (var storageId in data.storageURLs) {
    for (var j = 0; j < data.projectIDs.length; j++) {
      if (storageId == data.projectIDs[j]) {
        for (var i = 0; i < data.storageURLs[storageId].length; i++) {
          finalStoarageURLs.push(data.storageURLs[storageId][i]);
        }
        break;
      }
    }
  }
  return finalStoarageURLs;
}
</script>

// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #f8b2b6 !important;
}

.reely--project {
  font-size: 18px;
}

.reely--name {
  font-size: 14px;
  color: #f8b2b6;
}

.reely--details {
  font-size: 16px;
}

.reely--btn {
  text-transform: none;
}

.empty--state {
  font-family: "SimplonNorm-Medium";
}

.dark--blue {
  color: #1a4851;
}
</style>
 