<script>
export default {
  props: {
    title: String,
    textLabel: String,
    progressText: String,
    progressValue: Number,
    dialog: Boolean
  },
};
</script>

<template>
  <div id="progess-dialogue">
    <v-layout row justify-center>
      <v-dialog v-model="dialog" max-width="550">
        <v-card>
          <v-card-title className="headline" class="dialog-header-section">{{title}}</v-card-title>
          <v-card-text>
            {{textLabel}}
          </v-card-text>
          <v-container>
            <v-progress-linear color="teal" :value="progressValue" stream>
            </v-progress-linear>
          </v-container>
          <p class="zipped-videos-text">
            {{progressText}}
          </p>
          <br />
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<style>
.dialog-header-section {
  background-color: #eeeeee;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 18px !important;
  font-weight: 500;
}
.dialog-header-section + .v-card__text {
  padding: 14px 16px 0px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 15px !important;
}
.zipped-videos-text {
  text-align: center;
  padding: 4px 0px 0px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-size: 16px !important;
}
.container {
  padding: 0px 18px !important;
}
</style>
