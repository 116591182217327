// Template.
<template>
  <v-card class="mx-auto pa-4" max-width="800" elevation="0">
    <template>
      <div class="empty--state" align="center" v-if="noItems">
        No videos found.
      </div>

      <div>
        <v-card elevation="0" v-for="(item, index) in items" :key="index">
          <v-card-text>
            <v-container pa-0 elevation="0">
              <v-row align="center" no-gutters>
                <v-col
                  cols="6"
                  class="reely--project larsseit-bold dark--blue"
                  >{{ item.project }}</v-col
                >
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="reely--name larsseit-bold">{{
                  item.name
                }}</v-col>
              </v-row>
              <v-col col="12"> </v-col>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.duration
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">
                  Order ID: {{ item.docId }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">
                  Total Clips: {{ item.storageURLs.length }}
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.orientation
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.upgrade
                }}: {{ item.upgradeExist ? "Yes" : "No" }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.mood
                }}</v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" class="text-secondary larsseit">{{
                  item.notes
                }}</v-col>
              </v-row>
              <v-col col="12" style="padding: 0">
                <video-player-container :options="getVideoProps(item)" />
              </v-col>
              <v-row>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
    </template>
  </v-card>
  <!-- <v-app id="inspire">
    <div style="margin: 20px auto; with: 900px;">
      <v-card style="margin-bottom: 30px" v-for="videoEl in videos">
        <v-card-title class="reely--name larsseit-bold">
          {{ videoEl.title }}
        </v-card-title>
        <video-player-container :options="videoEl.options" />
      </v-card>
    </div>
  </v-app> -->
</template>


// Script.
<script>
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import "firebase/auth";
import util, { uploadToS3 } from "@/util";
import "firebase/storage";
import "firebase/functions";
import { getUrl, downloadFromS3 } from '../util';
import VideoPlayerContainer from './VideoPlayerContainer.vue';

export default {
  name: 'FinishedVideos',
  components: {
    VideoPlayerContainer
  },
  data() {
    return {
      items: [],
      noItems: false,
    };
  },
  methods: {
    getVideoProps(order) {
      const props = {
        width: 800,
        src: order.editedVideos,
        type: 'video/mp4'
      }
      return props;
    },
  },
  mounted() {
    var that = this;
    firebase
      .firestore()
      .collection("orders")
      // .where("edited", "==", true)
      .orderBy("dateSubmitted", "desc")
      .get()
      .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          let data = doc.data();
          let projectCount = data.projectIDs.length;
          for (var i = 0; i < projectCount; i++) {
            if (!data.edited || !data.editedVideos) {
              continue;
            }
            that.items.push({
              firstProject: i == 0,
              lastProject: i == projectCount - 1,
              project: data.titles[i],
              name: data.name,
              duration: util.durationString(data.durations[i]),
              upgrade: "Upgrades: Color Correction",
              upgradeExist: data.colorCorrections[i],
              mood: util.moodString(data.moods[data.projectIDs[i]]),
              notesExist: data.notes[i] != "",
              notes: util.notesString(data.notes[i]),
              date: moment(data.dateOrdered.toDate()).fromNow(),
              docId: doc.id,
              storageURLs: getStorageURLs(data),
              projectId: data.projectIDs[i],
              edited: data.edited,
              orientation: util.orientationString(data.orientations[i]),
              orderSubmitted: getOrderSubmittedString(data.dateSubmitted),
              editedVideos: data.editedVideos[0]
            });
          }
        });
        that.noItems = querySnapshot.docs.length == 0;
      })
      .catch(function(error) {
        console.log(error);
      });
  }
};

function getStorageURLs(data) {
  var finalStoarageURLs = [];
  for (var storageId in data.storageURLs) {
    for (var j = 0; j < data.projectIDs.length; j++) {
      if (storageId == data.projectIDs[j]) {
        for (var i = 0; i < data.storageURLs[storageId].length; i++) {
          finalStoarageURLs.push(data.storageURLs[storageId][i]);
        }
        break;
      }
    }
  }
  return finalStoarageURLs;
}

function getOrderSubmittedString(timestamp) {
  if (timestamp == null) {
    return "";
  }
  return "Order Submitted " + moment(timestamp.toDate()).fromNow();
}

</script>
// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #ffbec2 !important;
}
</style>