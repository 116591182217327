// Template.
<template>
  <v-card class="mx-auto pa-4" max-width="800" elevation="0">
    <template>
      <div align="center" class="pt-4">
        <v-btn outlined class="reely--text" @click="downloadMusicPack">Download Music Pack</v-btn>
      </div>
    </template></v-card
  >
</template
  ></v-card>
</template>


// Script.
<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { downloadFromS3 } from '@/util';
import multiDownload from "multi-download";

export default {
  data() {
    return {};
  },
  methods: {
    async downloadMusicPack() {
      const filename = "~REELY MUSIC~.zip";
      const musicRef = `music/${filename}`;
      const musicPackURL = await downloadFromS3(firebase, musicRef);
      multiDownload([musicPackURL]);
    }
  },
  mounted() {},
};
</script>

// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #f8b2b6 !important;
  text-align: center;
}
.larsseit {
  font-family: "Larsseit";
}
.larsseit-bold {
  font-family: "Larsseit-Bold";
}
.simplon-norm {
  font-family: "SimplonNorm-Medium";
}
</style>

