// Template.
<template>
  <v-app id="inspire">
    <v-card color="reely" elevation="0">
      <v-card-title class="text-center justify-center py-6">
        <h1 class="display-2 reely--text">Reely</h1>
      </v-card-title>

      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="reely"
        centered
      >
        <v-tab v-for="item in items" :key="item.title" :to="item.route">
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card color="reely" flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <router-view></router-view>
    </v-card>
  </v-app>
</template>


// Script.
<script>
export default {
  data() {
    return {
      tab: null,
      items: [
        { title: "All Jobs", route: "/" },
        { title: "My Jobs", route: "/myjobs" },
        { title: "Music", route: "/music" },
        { title: "Settings", route: "/settings" },
        { title: "Dashboard", route: "/dashboard" },
        { title: "Verify", route: "/verify" },
        { title: "Finished Videos", route: "/finished" },
      ],
    };
  },
};
</script>

// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #ffbec2 !important;
}
</style>