import Vue from "vue";
import VueRouter from "vue-router";
import App from "../App.vue";
import AllJobs from "../views/AllJobs.vue";
import MyJobs from "../views/MyJobs.vue";
import Settings from "../views/Settings.vue";
import Dashboard from "../views/Dashboard.vue";
import Verify from "../views/Verify.vue";
import Finished from "../views/Finished.vue";
import Music from '../views/Music.vue';
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(VueRouter);

// Routes.
const routes = [
  {
    path: "/",
    name: "alljobs",
    component: AllJobs,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/myjobs",
    name: "myjobs",
    component: MyJobs,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/music",
    name: "music",
    component: Music,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/verify",
  //   name: "verify",
  //   component: Verify,
  //   meta: {
  //     requiresAuth: false,
  //   },
  // },
  {
    path: "/verify/:id",
    name: "verify",
    component: Verify,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/finished",
    name: "finished",
    component: Finished,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// Firebase configuration.
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGE_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_API
};

firebase.initializeApp(firebaseConfig);

// if (window.location.hostname === 'localhost') {
//   firebase.firestore().useEmulator('localhost', 8080);
//   firebase.functions().useEmulator('localhost', 5001);
// }

// Firebase authentication state changed.
// firebase.auth().onAuthStateChanged((user) => {
//   let loggedIn = user != null;

//   if (loggedIn) {
//     router.push(window.location.pathname);
//     document.getElementById("tabs").style.visibility = "visible";
//     document.getElementById("tabs").style.display = "inline";
//   } else {
//     router.push("/login");
//     document.getElementById("tabs").style.display = "none";
//   }
// });

function updateDocumentStyle(user) {
  let loggedIn = user != null;

  if (loggedIn) {
    // router.push(window.location.pathname);
    document.getElementById("tabs").style.visibility = "visible";
    document.getElementById("tabs").style.display = "inline";
  } else {
    // router.push("/login");
    document.getElementById("tabs").style.display = "none";
  }
}

// Router before each.
router.beforeEach(async (to, from, next) => {
  // const user = await new Promise((resolve, reject) => {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     resolve(user);
  //   });
  // });

  const requiresAuth = to.matched.some(
    (recordedRoute) => recordedRoute.meta.requiresAuth
  );

  if (requiresAuth) {
    const user = await new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        resolve(user);
      });
    });
    updateDocumentStyle(user);
    if (!user) {
      next("/login");
      return;
    }
  }
  next();
});

export default router;
