// Template.
<template>
  <v-container>
    <v-layout>
      <v-flex>
        <v-card class="mx-auto" max-width="500">
          <v-card-text>
            <v-container>
              <form>
                <v-layout row>
                  <v-flex>
                    <v-text-field
                      class="larsseit"
                      placeholder=" "
                      name="email"
                      label="Email"
                      id="email"
                      v-model="emailString"
                      type="email"
                      color="#ffbec2"
                    />
                  </v-flex>
                </v-layout>

                <v-layout row>
                  <v-flex>
                    <v-text-field
                      class="larsseit"
                      placeholder=" "
                      name="password"
                      label="Password"
                      id="password"
                      v-model="passwordString"
                      type="password"
                      color="#ffbec2"
                    />
                  </v-flex>
                </v-layout>
              </form>

              <v-layout align-end justify-end>
                <v-btn
                  :loading="loading"
                  elevation="0"
                  class="reely--btn mt-4"
                  color="#F8B2B6"
                  @click="login"
                  >LOG IN</v-btn
                >
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
        <v-alert
          v-if="isError"
          class="mt-4 error2"
          color="#D9B029"
          max-width="500"
          dense
          outlined
          type="error"
        >
          {{ errorTitle }}
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

// Script.
<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      emailString: "",
      passwordString: "",
      emailTest: "123",
      loading: false,
      errorTitle: "Test",
      isError: false,
    };
  },

  methods: {

    // Login.
    login() {
      let loginThis = this;

      if (this.emailString == "" || this.passwordString == "") {
        loginThis.setError(
          "Please fill out both email and password to continue."
        );
        return;
      }

      loginThis.loading = true;

      var checkEditorLogin = firebase
        .functions()
        .httpsCallable("checkEditorLogin");
      checkEditorLogin({ email: this.emailString })
        .then((result) => {
          if (result.data.verifiedEditor) {
            firebase
              .auth()
              .signInWithEmailAndPassword(this.emailString, this.passwordString)
              .then((result) => {
                loginThis.loading = true;
                this.$router.push("/");
              })
              .catch((error) => {
                if (error.code == "auth/user-not-found") {
                  firebase
                    .auth()
                    .createUserWithEmailAndPassword(
                      this.emailString,
                      this.passwordString
                    )
                    .then((result) => {
                      loginThis.loading = true;
                    })
                    .catch((error) => {
                      if (error.code == "auth/weak-password") {
                        loginThis.setError(
                          "Password must be at least 8 characters."
                        );
                      }
                    });
                } else if (error.code == "auth/wrong-password") {
                  loginThis.setError("The password you entered is incorrect.");
                }
              });
          } else {
            loginThis.setError(
              "The email you entered is not associated with a verified editor account. Please contact hello@reelyfilms.com."
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Set error.
    setError(error) {
      this.errorTitle = error;
      this.isError = true;
      this.loading = false;
    },
  },
};
</script>


<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #ffbec2 !important;
}

.reely--project {
  font-size: 18px;
}

.reely--name {
  font-size: 14px;
  color: #ffbec2;
}

.reely--details {
  font-size: 16px;
}

.reely--btn {
  text-transform: none;
}
.error2 {
  margin: auto;
  font-family: "Larsseit";
}
</style>