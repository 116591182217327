// Template.
<template>
  <v-card class="mx-auto pa-4" max-width="800" elevation="0">
    <template v-if="dataLoaded">
      <v-row align="center" justify="center" no-gutters>
        <v-col class="d-flex justify-left larsseit" cols="3"
          >Available to Work</v-col
        >
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end" cols="3">
          <v-switch
            @change="availableToWork()"
            v-model="availableToWorkSwitch"
            inset
            dense
            color="#F8B2B6"
          ></v-switch>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <div align="left" class="pt-4">
        <v-btn outlined class="reely--text" @click="logout"> Log Out </v-btn>
      </div>
    </template></v-card
  >
</template
  ></v-card>
</template>


// Script.
<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export default {
  data() {
    return {
      availableToWorkSwitch: false,
      dataLoaded: false,
    };
  },
  methods: {

    // Logout.
    logout() {
      document.getElementById("tabs").style.display = "none";
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("Logged Out");
          this.$router.push("/login");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Set available to work.
    availableToWork() {
      firebase
        .firestore()
        .collection("editors")
        .doc(firebase.auth().currentUser.email)
        .update({
          availableToWork: this.availableToWorkSwitch,
        })
        .then(function () {})
        .catch((error) => {});
    },
  },
  mounted() {
    let mountedThis = this;

    // Read available to work switch.
    firebase
      .firestore()
      .collection("editors")
      .doc(firebase.auth().currentUser.email)
      .get()
      .then(function (doc) {
        if ("availableToWork" in doc.data()) {
          mountedThis.availableToWorkSwitch = doc.data().availableToWork;
        }
        mountedThis.dataLoaded = true;
      })
      .catch((error) => {});
  },
};
</script>

// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #f8b2b6 !important;
}
.larsseit {
  font-family: "Larsseit";
}
.larsseit-bold {
  font-family: "Larsseit-Bold";
}
.simplon-norm {
  font-family: "SimplonNorm-Medium";
}
</style>

