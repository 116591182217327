// Template.
<template>
  <v-app id="inspire">
    <v-card color="reely" elevation="0">
      <v-card-title class="text-center justify-center py-6">
        <v-img
          lazy-src="myImage"
          max-height="50"
          max-width="100"
          :src="myImage"
        ></v-img>
      </v-card-title>

      <v-tabs
        style="visibility: hidden"
        id="tabs"
        v-model="tab"
        background-color="transparent"
        color="reely"
        centered
      >
        <v-tab v-for="item in items" :key="item.title" :to="item.route">
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item> </v-tab-item>
      </v-tabs-items>
      <router-view></router-view>
    </v-card>
  </v-app>
</template>


// Script.
<script>
import "firebase/auth";

export default {
  data() {
    return {
      tab: null,
      items: [
        { title: "All Jobs", route: "/" },
        { title: "My Jobs", route: "/myjobs" },
        { title: "Music", route: "/music" },
        { title: "Dashboard", route: "/dashboard" },
        { title: "Finished Videos", route: "/finished" },
        { title: "Settings", route: "/settings" },
      ],
      loggedIn: false,
      myImage: require("@/logo.png"),
    };
  },
};
</script>

// Style.
<style>
/* Helper classes */

.reely {
  background-color: #ffffff !important;
}

.reely--text {
  color: #f8b2b6 !important;
  font-family: "SimplonNorm-Medium";
}

@font-face {
  font-family: "Larsseit";
  src: local("Larsseit"), url(/fonts/Larsseit.ttf) format("truetype");
}

@font-face {
  font-family: "Larsseit-Bold";
  src: local("Larsseit-Bold"), url(/fonts/Larsseit-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SimplonNorm-Medium";
  src: local("SimplonNorm-Medium"),
    url(/fonts/SimplonNorm-Medium.otf) format("truetype");
}
</style>