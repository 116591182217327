// Template.
<template>
  <v-app id="inspire">
    <v-snackbar
      :value="snackbarOpen"
      left
      :color="snackbarShowSuccess ? 'success' : 'red accent-2'"
    >
      {{ snackbarText }}
    </v-snackbar>
    <v-card v-if="this.loadingAccountStatus" color="reely" elevation="0">
      <v-card-title class="text-center justify-center py-6">
        <moon-loader
          :loading="this.loadingAccountStatus"
          :color="'#f8b2b6'"
        ></moon-loader>
      </v-card-title>
    </v-card>
    <v-card v-else-if="!this.stripeStatus" color="reely" elevation="0">
      <v-card-text class="text-center justify-center">
        {{ this.accountCreateText }}
        <br />
        <br />
        <v-btn @click="createConnectedAccount()" outlined class="reely--text">
          Create Connected Account
        </v-btn>
        <v-card-title class="text-center justify-center py-6">
          <moon-loader
            :loading="this.accountCreateInProgress"
            :color="'#f8b2b6'"
          ></moon-loader>
        </v-card-title>
      </v-card-text>
    </v-card>
    <v-card
      v-else-if="this.stripeStatus === 'onboarding_pending'"
      color="reely"
      elevation="0"
    >
      <v-container v-if="this.linkAccountUrl == null">
        <v-card-text class="text-center justify-center">
          Please click on the following button to
          {{ this.onboardingPendingAlready ? "continue" : "start" }} onboarding
          process.
          <br />
          <br />
          <v-btn @click="startOnboardingProcess()" outlined class="reely--text">
            {{ this.onboardingPendingAlready ? "Continue" : "Start" }}
            Onboarding Process
          </v-btn>
        </v-card-text>
        <v-card-title class="text-center justify-center py-6">
          <moon-loader
            :loading="this.linkAccountInProcess"
            :color="'#f8b2b6'"
          ></moon-loader>
        </v-card-title>
      </v-container>
      <v-container class="text-center justify-center" v-else>
        <br />
        <br />
        <v-row no-gutters>
          <v-col cols="12" class="text-secondary larsseit">
            <div v-if="this.linkAccountUrl != null">
              Use the following link to complete the onboarding process.
              <a target="_blank" class="reely--text" :href="this.linkAccountUrl"
                >Link Account</a
              >
              <p>You can refresh this page after completing the process.</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      class="text-center justify-center"
      v-else-if="
        this.stripeStatus === 'onboarding_completed' ||
          this.stripeStatus === 'onboarded'
      "
      color="reely"
      elevation="0"
    >
      <p v-if="this.stripeStatus === 'onboarding_completed'">
        Note: Your stripe account is not fully onboarded yet, you may need to
        update pending information on stripe dashboard.
      </p>
      <v-card-title class="text-center justify-center py-6">
        <moon-loader
          :loading="this.loginLinkInProgress"
          :color="'#f8b2b6'"
        ></moon-loader>
      </v-card-title>
      <a
        v-if="this.loginLinkUrl !== null"
        :href="this.loginLinkUrl"
        target="_blank"
        class="reely--text"
        >Go to stripe dashboard</a
      >
    </v-card>

    <v-card
      class="mx-auto"
      width="800"
      elevation="0"
      v-for="(item, index) in items"
      :key="index"
    >
      <v-card-text>
        <v-container pa-0 elevation="0">
          <v-row align="center" no-gutters>
            <v-col cols="6" class="reely--project larsseit-bold dark--blue">{{
              item.project
            }}</v-col>
            <v-col
              v-if="item.firstProject"
              cols="6"
              class="text-right reely--textsize simplon-norm"
              >{{ item.pay }}</v-col
            >
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="reely--name larsseit-bold">{{
              item.name
            }}</v-col>
          </v-row>
          <v-col col="12"> </v-col>

          <v-row no-gutters>
            <v-col cols="12" class="text-secondary larsseit">{{
              item.duration
            }}</v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="text-secondary larsseit">
              Total Clips: {{ item.storageURLs.length }}
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="text-secondary larsseit">{{
              item.orientation
            }}</v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="text-secondary larsseit"
              >{{ item.upgrade }}: {{ item.upgradeExist ? "Yes" : "No" }}</v-col
            >
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="text-secondary larsseit">{{
              item.mood
            }}</v-col>
          </v-row>

          <v-row no-gutters>
            <v-col
              v-if="item.notesExist"
              cols="12"
              class="text-secondary larsseit"
              >{{ item.notes }}</v-col
            >
          </v-row>

          <v-row no-gutters align="center">
            <v-col cols="6" class="simplon-norm">{{ item.date }}</v-col>
            <v-col cols="6" class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-if="!item.dateDownloaded" v-bind="attrs" v-on="on">
                    <v-btn
                      class="reely--text simplon-norm"
                      outlined
                      disabled
                      :loading="payoutRequestInProgress"
                    >
                      Pending Completion
                    </v-btn>
                  </span>
                  <span v-else v-bind="attrs">
                    <v-btn
                      :disabled="
                        stripeStatus !== 'onboarded' ||
                          item.transaction.editorPaid
                      "
                      :loading="payoutRequestInProgress"
                      @click="payoutJob(item.docId, index)"
                      outlined
                      class="reely--text simplon-norm"
                    >
                      {{ item.transaction.editorPaid ? "Paid" : "Pay out now" }}
                    </v-btn>
                  </span>
                </template>
                <span>{{ !item.dateDownloaded ? "Waiting for the user to download files." : "" }}</span>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-col col="12"> </v-col>

          <v-row>
            <v-col v-if="item.lastProject" cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-app>
</template>

// Script.
<script>
import firebase from "firebase/app";
import "firebase/functions";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";
import moment from "moment";
import util from "@/util";

export default {
  data() {
    return {
      items: [],
      snackbarOpen: false,
      snackbarText: "",
      snackbarShowSuccess: false,
      noItems: false,
      linkExpiresTime: null,
      linkAccountUrl: null,
      linkAccountInProcess: false,
      loadingAccountStatus: false,
      loginLinkInProgress: false,
      loginLinkUrl: null,
      stripeStatus: null,
      onboardingPendingAlready: false,
      accountCreateInProgress: false,
      accountCreateFailed: false,
      payoutRequestInProgress: false,
      accountCreateText:
        "To get started on dashboard, Please click on the following button to create stripe connected account.",
    };
  },
  components: {
    MoonLoader,
  },
  methods: {
    async createConnectedAccount() {
      if (this.accountCreateInProgress) {
        return;
      }
      this.accountCreateInProgress = true;
      const email = firebase.auth().currentUser.email;
      const { data } = await firebase
        .functions()
        .httpsCallable("createConnectedAccount")({
          email: email,
          country: "US",
        })
        .catch((err) => {
          this.accountCreateFailed = true;
          this.accountCreateText =
            "Account creation failed. Try again and if the problem presists, contact admin.";
          return {
            data: {},
          };
        });
      if (data.statusCode !== 200) {
        this.accountCreateFailed = true;
        this.accountCreateText =
          "Account creation failed. Try again and if the problem presists, contact admin.";
        this.accountCreateInProgress = false;
        return;
      } else if (data.statusCode === 200) {
        this.stripeStatus = "onboarding_pending";
      }
      this.accountCreateInProgress = false;
    },

    async startOnboardingProcess() {
      if (
        this.linkAccountInProcess ||
        this.stripeStatus !== "onboarding_pending"
      ) {
        return;
      }
      this.linkAccountInProcess = true;
      const { data } = await firebase
        .functions()
        .httpsCallable("linkConnectedAccount")({
        email: firebase.auth().currentUser.email,
      });
      this.linkAccountUrl = data.url;
      this.linkExpiresTime = data.expiresAt;
      this.linkAccountInProcess = false;
    },

    async getLoginLink() {
      if (this.loginLinkInProgress) {
        return;
      }
      this.loginLinkInProgress = true;
      const email = firebase.auth().currentUser.email;
      const { data } = await firebase.functions().httpsCallable("getLoginLink")(
        {
          email: email,
        }
      );
      this.loginLinkUrl = data.url;
      this.loginLinkInProgress = false;
    },

    async payoutJob(docId, index) {
      const email = firebase.auth().currentUser.email;

      this.payoutRequestInProgress = true;
      try {
        const { data } = await firebase
          .functions()
          .httpsCallable("payoutEditorForOrder")({
          email: email,
          orderId: docId,
        });
        if (data.statusCode === 200) {
          this.items[index].transaction = data.transaction;
          this.showSnackbar("Request Succeeded.");
        }
      } catch (e) {
        this.showSnackbar(`Request Failed, ${e.message}`);
      }

      this.payoutRequestInProgress = false;
    },

    showSnackbar(text, success = false) {
      this.snackbarOpen = true;
      this.snackbarText = text;
      this.snackbarShowSuccess = success;
    },

    async populateItemsFromFirebase() {
      const that = this;
      await firebase
        .firestore()
        .collection("orders")
        .where("editorId", "==", firebase.auth().currentUser.uid)
        .orderBy("dateAccepted", "desc")
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            let data = doc.data();
            let projectCount = data.projectIDs.length;
            for (var i = 0; i < projectCount; i++) {
              that.items.push({
                firstProject: i == 0,
                lastProject: i == projectCount - 1,
                project: data.titles[i],
                name: data.name,
                pay: util.payString(data),
                duration: util.durationString(data.durations[i]),
                upgrade: "Upgrades: Color Correction",
                upgradeExist: data.colorCorrections[i],
                mood: util.moodString(data.moods[data.projectIDs[i]]),
                notesExist: data.notes[i] != "",
                notes: util.notesString(data.notes[i]),
                date: moment(data.dateOrdered.toDate()).fromNow(),
                docId: doc.id,
                storageURLs: getStorageURLs(data),
                projectId: data.projectIDs[i],
                edited: data.edited,
                orientation: util.orientationString(data.orientations[i]),
                orderSubmitted: getOrderSubmittedString(data.dateSubmitted),
                hasSubmitted: data.dateSubmitted !== null,
                submitError: false,
                adobeProjectFile: null,
                editedProjectFile: null,
                transaction: data.transaction || {},
                dateDownloaded: data.dateDownloaded,
              });
            }
          });
          that.noItems = querySnapshot.docs.length == 0;
        })
        .catch(function(error) {
          console.log(error);
        });
      this.items
        .sort((a, b) => {
          return a.dateDownloaded - b.dateDownloaded;
        })
        .reverse();
    },
  },

  async mounted() {
    this.loadingAccountStatus = true;
    const email = firebase.auth().currentUser.email;
    try {
      const connectedAccount = await firebase
        .functions()
        .httpsCallable("getConnectedAccount")({
        email: email,
      });
      this.onboardingPendingAlready = true;
      this.stripeStatus = connectedAccount.data.stripeStatus;
    } catch (e) {
      console.log(e);
    }
    this.loadingAccountStatus = false;
    if (
      this.stripeStatus === "onboarding_completed" ||
      this.stripeStatus === "onboarded"
    ) {
      await this.getLoginLink();
    }
    this.populateItemsFromFirebase();
  },
};

function getOrderSubmittedString(timestamp) {
  if (timestamp == null) {
    return "";
  }
  return "Order Submitted " + moment(timestamp.toDate()).fromNow();
}

// Get storage urls with data.
function getStorageURLs(data) {
  var finalStoarageURLs = [];
  for (var storageId in data.storageURLs) {
    for (var j = 0; j < data.projectIDs.length; j++) {
      if (storageId == data.projectIDs[j]) {
        for (var i = 0; i < data.storageURLs[storageId].length; i++) {
          finalStoarageURLs.push(data.storageURLs[storageId][i]);
        }
        break;
      }
    }
  }
  return finalStoarageURLs;
}
</script>

// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #ffbec2 !important;
}
</style>
