<template>
  <div>
    <video :width="this.options.width" ref="videoPlayer" class="video-js vjs-default-skin vjs-big-play-centered"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
// import 'videojs-youtube/dist/Youtube.min.js';

import 'video.js/dist/video-js.min.css';

// import 'video.js/dist/video.min.js';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    console.log(this.$refs.videoPlayer, this.options)
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log('onPlayerReady', this);
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>