var util = {
  // Orientation string from orientation.
  orientationString(orientation) {
    return (
      "Orientation: " +
      orientation.charAt(0).toUpperCase() +
      orientation.slice(1)
    );
  },

  // Mood string from moods.
  moodString(moods) {
    var moodString = "";
    if (moods == null) {
      return "";
    }
    for (var i = 0; i < moods.length; i++) {
      moodString += moods[i];
      if (i != moods.length - 1) {
        moodString += ", ";
      }
    }
    return "Moods: " + moodString;
  },

  // Notes string from notes.
  notesString(notes) {
    if (notes == "") {
      return notes;
    }
    return "Notes to Editor: " + notes;
  },

  // Duration string from seconds.
  durationString(seconds) {
    var mins = Math.floor(seconds / 60);
    var secs = seconds % 60;
    var suffix = " minutes";
    if (secs < 10) {
      secs = "0" + secs;
    }
    if (seconds < 60) {
      suffix = " seconds";
    }
    var time = mins + ":" + secs;
    return "Clip Duration: " + time + suffix;
  },

  // Pay string from data.
  payString(data) {
    var pay = 0;
    var hours = 0;
    for (var i = 0; i < data.projectIDs.length; i++) {
      pay += 20;
      hours += 1;
      if (data.addons && data.addons.includes("digital-color")) {
        pay += 2.5;
        hours += 0.25;
      }
    }
    return formatMoney(pay) + " | " + hours + " hr Edit Time";
  },
};

// Format money from number.
function formatMoney(number) {
  return number.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export function getUrl(bucketName, zipFilePath, orderId) {
  const fileUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodeURIComponent(
    zipFilePath
  )}?alt=media&token=${orderId}`;
  return fileUrl;
}

export function getPublicUrl(key) {
  return `https://${process.env.VUE_APP_BUCKET}.s3.${process.env.VUE_APP_REGION}.amazonaws.com/${key}`;
}

const axios = require("axios");

export async function uploadToS3(firebase, key, body, progressCallback, acl = 'public-read') {
  const putS3SignedUrl = firebase.functions().httpsCallable("putS3SignedUrl");
  const { data: url } = await putS3SignedUrl({
    bucket: process.env.VUE_APP_BUCKET,
    key,
    contentType: body.type,
    acl: acl
  });
  let newUrl = new URL(url);
  await axios({ 
    url: newUrl.href,
    method: "put",
    data: body,
    onUploadProgress: progressEvent => progressCallback(progressEvent)
  });
  return getPublicUrl(encodeURIComponent(key));
}

export async function downloadFromS3(firebase, key) {
  const getS3SignedUrl = firebase.functions().httpsCallable("getS3SignedUrl");
  const { data: url } = await getS3SignedUrl({
    bucket: process.env.VUE_APP_BUCKET,
    key
  });
  return url;
}


export default util;
