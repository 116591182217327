// Template.
<template>
  <v-app id="inspire">
    <v-card color="reely" elevation="0">
      <v-card-title class="text-center justify-center py-6">
        <h3 class="reely--text" style="padding-right: 20px;">{{ verifyText }}</h3>
          <moon-loader :loading="this.verifying" :color="'#f8b2b6'" :size="50"></moon-loader>
      </v-card-title>
    </v-card>
  </v-app>
</template>


// Script.
<script>
import firebase from "firebase/app";
import "firebase/functions";
import MoonLoader from "vue-spinner/src/MoonLoader.vue";

export default {
  data() {
    return {
      verifying: false,
      verifyText: 'Verifying'
    };
  },
  components: {
    MoonLoader,
  },
  methods: {

  },

  async mounted() {
    this.verifying = true;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (!params || !params.id) {
      this.$router.push("/login");
      return;
    }

    const pathname = window.location.pathname;
    const email = pathname.substring(pathname.lastIndexOf("/") + 1);

    try {
      const verifyAccount = await firebase
        .functions()
        .httpsCallable("verifyAccountLink")({
        email: email,
        id: params.id
      });
      if (verifyAccount.data.statusCode === 200) {
        this.verifyText = "Verification Completed. Redirecting you to your dashboard.";
        const url = window.location.origin + "/dashboard";
        window.location.replace(url);
        return;
      }
    } catch (e) {
      console.log(e);
      this.verifyText = "Could not verify your account. Please try again or contact admin."
    }
    this.verifying = false;
  }
};
</script>

// Style.
<style>
/* Helper classes */
.reely {
  background-color: #ffffff !important;
}
.reely--text {
  color: #ffbec2 !important;
}
</style>